import {
  Container,
  Fab,
  Fade,
  Grid,
  Paper,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import { UserContext } from "../../context/user-context";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "./style.scss";
import { Link, useHistory } from "react-router-dom";
import QRCode from "../qrcode";
import { DContent } from "../../model";
import { UserContext } from "../../context/user-context";

export default function Footer(props: any) {
  const trigger = useScrollTrigger({ target: window ? window : undefined });
  const { t, i18n } = useTranslation("content");

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const history = useHistory();

  let uri = window.location.href;

  const [footer, setFooter] = useState({} as DContent);

  useEffect(() => {
    window.addEventListener("hashchange", () => {
      uri = window.location.href;
    });

    context.init().then(() => {
      //console.log(JSON.stringify(context.business.navItems));
      setFooter(context.business.footer as DContent);
    });
  }, []);

  return (
    <div className="footer">
      <Container>
        <Grid container spacing={8} justifyContent="flex-start">
          <Grid item xs={12}>
            <div>
              <QRCode text={uri} />
              {footer && (
                <>
                  <h4 className="center">{footer.title}</h4>
                  {footer.children?.map((child, index) => {
                    return (
                      <h5 className="center" key={`footer-h-${index}`}>
                        {child.content}
                      </h5>
                    );
                  })}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
