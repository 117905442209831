import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import AddIcon from "@mui/icons-material/Add";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import {
  Avatar,
  Box,
  CardHeader,
  Chip,
  Divider,
  Hidden,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";

import API from "@aws-amplify/api";
import { DObject } from "./ubq";
import { UserContext } from "../context/user-context";
import QRCode from "../components/qrcode";
import BTextField from "../components/form/text-field";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import BSelectList from "../components/form/select-list";
import { UObject } from "./u-object";

//const IoTCard = (props: React.PropsWithChildren<DJob>) => {
const IoTCard = (props: any) => {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const history = useHistory();
  const routeTo = (route: string) => {
    history.push(route);
  };

  const [iot, setIot] = useState(props.iot as any);
  const [isNew, setIsNew] = useState(false);

  const action = props.action as any;

  const handleAction = () => {
    console.log("handleAction ...");
    action(iot, isNew).then((iot: any) => {
      // parent handles newly created object
      // we simply reset:
      if (isNew) {
        reset();
      }
    });
  };

  const reset = () => {
    setIsNew(true);
    setIot(UObject.init());
  };

  useEffect(() => {
    if (iot == null) {
      reset();
    }
  }, []);

  return iot != null ? (
    <Card className="job-card" raised>
      <Box
        sx={{
          width: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <Box>
          <QRCode text={`${context.urlRoot}/jobs/`}></QRCode>
        </Box>
        <Box>
          <Chip label="test" size="small" onClick={() => {}} />
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          alignContent: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <BSelectList
            label="IoT Map"
            editMode={false}
            name="map"
            value={iot?.map}
            list={["map-id-demo-123", "map-id-home-7688"]}
            onChange={(e: any) => {
              setIot({ ...iot, map: e.target.value });
            }}
          ></BSelectList>
          <BTextField
            label="IoT Device ID"
            editMode={false}
            name="device-id"
            value={iot.id}
            onChange={(e: any) => {
              setIot({ ...iot, id: e.target.value });
            }}
          ></BTextField>
          <BTextField
            label="IoT Device Label"
            editMode={false}
            name="device-label"
            value={iot.label}
            onChange={(e: any) => {
              setIot({ ...iot, label: e.target.value });
            }}
          ></BTextField>
          <BTextField
            label="X-Coordinate"
            editMode={false}
            name="device-x"
            value={iot.x}
            onChange={(e: any) => {
              setIot({ ...iot, x: parseInt(e.target.value) });
            }}
          ></BTextField>
          <BTextField
            label="Y-Coordinate"
            editMode={false}
            name="device-y"
            value={iot.y}
            onChange={(e: any) => {
              setIot({ ...iot, y: parseInt(e.target.value) });
            }}
          ></BTextField>
          <BTextField
            label="Marker Color"
            editMode={false}
            name="device-color"
            value={iot.color}
            onChange={(e: any) => {
              setIot({ ...iot, color: e.target.value });
            }}
          ></BTextField>
        </Box>
      </Box>

      <Box
        flexGrow={1}
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <h3>{props.children}</h3>
        <h1>
          <br />
        </h1>
      </Box>
      <Box
        flexGrow={1}
        sx={{
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {isNew ? (
          <Button variant="contained" color="warning" onClick={handleAction}>
            <AddIcon /> Add
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={handleAction}>
            <EditLocationAltIcon /> Update
          </Button>
        )}
      </Box>
    </Card>
  ) : (
    <></>
  );
};

export default IoTCard;
