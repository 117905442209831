import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Button, Container, Fade, Grid, TextField } from "@mui/material";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import { DNameValue, DProfile, DUser, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import { DPayment } from "../model/my-bsg";
import PaymentCard from "../components/card/payment-card";
import IconCard from "../components/card/icon-card";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import NameValueCard from "../components/card/name-value-card";
import BTextField from "../components/form/text-field";
import BSelectList from "../components/form/select-list";
import { BSGAPI } from "../auth/bsg-api";

const MyProfile = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useHistory();

  const [webContent, setWebContent] = useState({} as DWebContent);
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [gdrive, setGdrive] = useState("");
  let [profile, setProfile] = useState({} as DProfile);

  let [attributes, setAttributes] = useState([] as DNameValue[]);

  let [contactCardDisabled, setContactCardDisabled] = useState(true);
  let [payCardDisabled, setPayCardDisabled] = useState(true);

  const getAttributeValue = (name: string) => {
    let attribute = attributes.find(
      (attr: DNameValue) => attr.Name == name
    ) as DNameValue;
    if (attribute != null) return attribute.Value;
  };

  const setAttribute = (name: string, value: string) => {
    let attribute = { Name: name, Value: value };

    let newAttributes = attributes.map((attr) => {
      return attr.Name == name ? attribute : attr;
    });
    let myAttribute = newAttributes.find(
      (attr: DNameValue) => attr.Name == name
    ) as DNameValue;
    if (myAttribute == null) {
      newAttributes.push(attribute);
    }
    setAttributes(newAttributes);
  };

  const updateMyAttributes = () => {
    BSGAPI.updateUserAttributes(
      context.user.cognitoCredentials,
      context.user.cognitoUser,
      attributes
    ).then((json) => {
      if (json.status == "ok") {
        //setAttributes(json.data.attributes as DNameValue[]);
      } else if (json.status == "token-expired") {
      } else {
        // TODO: exception handling ..
        //console.log("error deleting ...");
      }
    });
  };

  useEffect(() => {
    document.title = "BSG Partner:My Profile";

    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        // for testing:
        //getMyProfile();

        // just for testing:
        //getMyAttributes();

        setGdrive(context.user.person!.gdrive as string);

        BSGAPI.getUserAttributes(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((json) => {
          if (json.status == "ok") {
            setAttributes(json.data.attributes as DNameValue[]);
          } else if (json.status == "token-expired") {
          } else {
            // TODO: exception handling ..
            //console.log("error deleting ...");
          }
        });
        forceUpdate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/profile`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in");
      }
    });

    BSGAPI.getWebContent("profile").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              justifyContent="center"
              alignContent="center"
            >
              <NameValueCard
                key="info-contact"
                icon={
                  <PermPhoneMsgIcon color="info" style={{ fontSize: 64 }} />
                }
                title="How We Contact You"
                description=""
                onClick={() => {
                  updateMyAttributes();
                  setContactCardDisabled(true);
                }}
                onClickText="Save"
                onClickDisabled={contactCardDisabled}
              >
                <BTextField
                  label="Mobile/SMS"
                  editMode={false}
                  name="mobile"
                  value={getAttributeValue("mobile")}
                  onChange={(e: any) => {
                    setAttribute("mobile", e.target.value);
                    setContactCardDisabled(false);
                  }}
                ></BTextField>
                <BTextField
                  label="WeChat"
                  editMode={false}
                  name="wechat"
                  value={getAttributeValue("wechat")}
                  onChange={(e: any) => {
                    setAttribute("wechat", e.target.value);
                    setContactCardDisabled(false);
                  }}
                ></BTextField>
              </NameValueCard>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              justifyContent="center"
              alignContent="center"
            >
              <NameValueCard
                key="info-payment"
                icon={
                  <MonetizationOnIcon color="info" style={{ fontSize: 64 }} />
                }
                title="How We Pay You"
                description=""
                onClick={() => {
                  updateMyAttributes();
                  setContactCardDisabled(true);
                }}
                onClickText="Save"
                onClickDisabled={payCardDisabled}
              >
                <h1></h1>
                <BSelectList
                  label="Payment Method"
                  editMode={false}
                  name="pay_method"
                  value={getAttributeValue("pay_method")}
                  list={["Venmo", "Paypal", "Check", "Zelle"]}
                  onChange={(e: any) => {
                    setAttribute("pay_method", e.target.value);
                    setPayCardDisabled(false);
                  }}
                ></BSelectList>
                <BTextField
                  label="Payment Method Info"
                  tip="Provide account info for this payment method. For check, please provide mailing address. "
                  editMode={false}
                  name="pay_method_detail"
                  value={getAttributeValue("pay_method_detail")}
                  onChange={(e: any) => {
                    setAttribute("pay_method_detail", e.target.value);
                    setPayCardDisabled(false);
                  }}
                ></BTextField>
              </NameValueCard>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              justifyContent="center"
              alignContent="center"
            >
              <IconCard
                key="info-tax"
                icon={
                  <AccountBalanceIcon color="info" style={{ fontSize: 64 }} />
                }
                title="Tax"
                description="1099 Form will be sent to you at the beginning of year when income is more than $600"
                onClick={() => {
                  window.open(
                    `https://drive.google.com/drive/u/1/folders/${gdrive}`,
                    "_blank"
                  );
                }}
                onClickText="Open G-Drive"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default MyProfile;
