import { Link, useHistory } from "react-router-dom";
import { DInquiry, DLink, DNameValue } from "../../model";
import { API } from "aws-amplify";
import { useState } from "react";
import {
  Button,
  Hidden,
  Menu,
  MenuItem,
  useScrollTrigger,
} from "@mui/material";

import "./style.scss";

export interface INavItem {
  link: DLink;
}

const BNavItem = ({ link }: INavItem) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Hidden
      lgUp={link.lgUp}
      lgDown={link.lgDown}
      mdUp={link.mdUp}
      mdDown={link.mdDown}
      smUp={link.smUp}
      smDown={link.smDown}
    >
      {link.children && link.children.length > 0 ? (
        <>
          <Button
            className="navButton"
            aria-haspopup="true"
            onClick={(e) => {
              history.push(link.uri as string);
              setAnchorEl(e.currentTarget);
            }}
          >
            Training
          </Button>

          <Menu
            id="menu-training"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {link.children.map((child) => {
              <MenuItem
                onClick={() => {
                  history.push(child.uri as string);
                  setAnchorEl(null);
                }}
              >
                {child.text}
              </MenuItem>;
            })}
          </Menu>
        </>
      ) : (
        <Button className="navButton" component={Link} to={link.uri as string}>
          {link.text}
        </Button>
      )}
    </Hidden>
  );
};

export default BNavItem;
