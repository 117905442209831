import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Container, Fade, Grid } from "@mui/material";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import { DPerson, DUser } from "../model";
import { ContextService } from "../context/context-service";
import { DPayment } from "../model/my-bsg";
import PaymentCard from "../components/card/payment-card";
import CandidateCard from "../components/card/candidate-card";

interface Person {
  firstname: string;
  lastname: string;
}

export interface Props {
  text: string;
  /**
     * 
    ok: boolean;
    id?: number;  // ? optional
    fn: (bob: string) => string;
    person: Person;
     */
}

const Candidate = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useHistory();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const [candidate, setCandidate] = useState({} as DPerson);

  const id = props.match.params.id;

  const classes: any = styles(theme);

  async function getMyCandidate() {
    //const apiName = "users";
    const apiName = "my-candidate";
    const path = "";
    const myInit = {
      headers: {
        "Cache-Control": "No-Cache",
        //"Cache-Control": ContextService.CACHE_MAX_AGE,
        //Authorization: `Bearer ${context.user.cognitoUser?.jwt}`, // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate
        Authorization: `Bearer ${context.user.cognitoCredentials?.AccessToken}`, // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate
      },
      queryStringParameters: {
        id: id,
      },
    };
    //let response = await API.get(apiName, path, myInit);
    //setJobs(response as DJob[]);

    API.get(apiName, path, myInit).then((data) => {
      //console.log("Data: " + JSON.stringify(data));
      setCandidate(data as DPerson);
    });
  }

  useEffect(() => {
    //context.getCognitoJWT().then((jwt) => {
    //  console.log("Cognito JWT Token: " + jwt);
    //});

    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        forceUpdate();
        getMyCandidate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/pay`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in");
      }
    });

    document.title = `${ContextService.SITE_NAME}:Candidate`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>My Payment</h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h2>We are glad you are part of the team. </h2>
          </Grid>
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            spacing={8}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid container item xs={12} sm={6} lg={3} justifyContent="center">
              <CandidateCard candidate={candidate}></CandidateCard>
            </Grid>

            <Grid container item xs={12}></Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Candidate;
