import React, { ReactElement, useEffect, useRef, useState } from "react";
import { UObject } from "./u-object";
import { DMap, DObject } from "./ubq";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import "./style.scss";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import HtmlTooltip from "../components/tooltip/tool-tip";

const UObjectInfo = (props: any) => {
  let object = props.object as DObject;

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [showInfo, setShowInfo] = useState(false);

  let tracker = useRef({
    frameCount: 0,
  });

  const draw = (ctx: any) => {
    if (ctx) {
      ctx.clearRect(
        0,
        0,
        2 * (object.size as number),
        2 * (object.size as number)
      );
      ctx.fillStyle = object.color;
      ctx.beginPath();
      ctx.arc(
        object.size,
        object.size,
        (object.size as number) *
          Math.sin(tracker.current.frameCount * 0.05) ** 2,
        0,
        2 * Math.PI
      );
      ctx.fill();
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    let animationFrameId: number;

    //Our draw came here
    const render = () => {
      tracker.current.frameCount++;
      //console.log("frameCount: " + frameCount);
      draw(context);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return (
    <div key={`object-${object.id}`}>
      <HtmlTooltip
        placement="right-start"
        arrow
        open={showInfo}
        title={
          <React.Fragment>
            <b>{object.label}</b>
            <br />
            ID: {object.id}
            <br />[{object.x}, {object.y}]
            <br />
            <em>Note</em> <b>{"some"}</b> <u>{"amazing content"}</u>.
            <br />
            UBQ Systems, Inc.
          </React.Fragment>
        }
        onClick={() => {
          setShowInfo(!showInfo);
        }}
      >
        <canvas
          ref={canvasRef}
          width={2 * (object.size as number)}
          height={2 * (object.size as number)}
          style={{
            border: "0px solid purple",
          }}
          onClick={() => {
            setShowInfo(!showInfo);
          }}
        />
      </HtmlTooltip>
    </div>
  );
};

export default UObjectInfo;
