import { DCognitoCredentials, DCognitoUser } from "../model";
import config from "./config";
export class BSGAuth {
  static COGNITO_USER: string = "COGNITO_USER";
  static COGNITO_CREDENTIALS: string = "COGNITO_CREDENTIALS";
  static MYBSG_PERSON: string = "MYBSG_PERSON";

  constructor() {}

  static config() {
    //console.log("This is config of my Auth class");
  }

  // get user from local storage
  static currentAuthenticatedUser() {
    return new Promise<any>((resolve, reject) => {
      let cognitoUser = localStorage.getItem(BSGAuth.COGNITO_USER);
      if (cognitoUser != null) {
        //console.log("credentials in localstorage: " + cognitoUser);
        resolve(JSON.parse(cognitoUser));
      } else {
        resolve(null);
        //throw new Error("current authenticated user is not found");
      }
      //else {
      //  return reject(new Error("current authenticated user is not found"));
      //}
    });
  }

  static currentCredentials() {
    return new Promise<any>((resolve, reject) => {
      let credentials = localStorage.getItem(BSGAuth.COGNITO_CREDENTIALS);
      if (credentials != null) {
        //console.log("credentials in localstorage: " + credentials);
        resolve(JSON.parse(credentials));
      } else {
        //throw new Error("current session is not found");
        resolve(null);
      }
      //else {
      //  return reject(new Error("current session is not found"));
      //}
    });
  }

  static signUp(params: {
    username: string;
    password: string;
    email: string;
    phone: string;
  }) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      };
      fetch("/api/pub/cognito-signUp", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //console.log("json: " + JSON.stringify(json));
          //  resolve(json.data);
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static confirmSignUp() {}

  static signIn(authParams: { username: string; password: string }) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(authParams),
      };
      fetch("/api/pub/cognito-signIn", options)
        .then((response) => response.json())
        .then((json) => {
          if (json.status == "ok") {
            //console.log("json: " + JSON.stringify(json));
            localStorage.setItem(
              BSGAuth.COGNITO_CREDENTIALS,
              JSON.stringify(json.credentials)
            );
            resolve(json.credentials);
          } else {
            localStorage.removeItem(BSGAuth.COGNITO_CREDENTIALS);
            reject();
          }
        })
        .catch((err) => {
          localStorage.removeItem(BSGAuth.COGNITO_CREDENTIALS);
          reject(err);
        });
    });
  }

  static oAuthSignIn(authParams: {
    authorization_code: string;
    redirect_uri: string;
  }) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(authParams),
      };
      fetch("/api/pub/cognito-oAuthSignIn", options)
        .then((response) => response.json())
        .then((json) => {
          if (json.status == "ok") {
            //console.log("json: " + JSON.stringify(json));
            localStorage.setItem(
              BSGAuth.COGNITO_CREDENTIALS,
              JSON.stringify(json.credentials)
            );
            resolve(json.credentials);
          } else {
            localStorage.removeItem(BSGAuth.COGNITO_CREDENTIALS);
            reject();
          }
        })
        .catch((err) => {
          localStorage.removeItem(BSGAuth.COGNITO_CREDENTIALS);
          reject(err);
        });
    });
  }

  static refreshToken(authParams: { refreshToken: string }) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(authParams),
      };
      fetch("/api/pub/cognito-refreshToken", options)
        .then((response) => response.json())
        .then((json) => {
          if (json.status == "ok") {
            //console.log("json: " + JSON.stringify(json));
            localStorage.setItem(
              BSGAuth.COGNITO_CREDENTIALS,
              JSON.stringify(json.credentials)
            );
            resolve(json.credentials);
          } else {
            localStorage.removeItem(BSGAuth.COGNITO_CREDENTIALS);
            reject();
          }
        })
        .catch((err) => {
          localStorage.removeItem(BSGAuth.COGNITO_CREDENTIALS);
          reject(err);
        });
    });
  }

  static getUser(accessToken: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ accessToken: accessToken }),
      };
      fetch("/api/pub/cognito-getUser", options)
        .then((response) => response.json())
        .then((json) => {
          if (json.status == "ok") {
            //console.log("json: " + JSON.stringify(json));
            localStorage.setItem(
              BSGAuth.COGNITO_USER,
              JSON.stringify(json.user)
            );
            resolve(json.user); // DCognitoUser
          } else {
            localStorage.removeItem(BSGAuth.COGNITO_USER);
            reject();
          }
        })
        .catch((err) => {
          localStorage.removeItem(BSGAuth.COGNITO_USER);
          reject(err);
        });
    });
  }

  static signOut() {
    return new Promise<void>((resolve, reject) => {
      localStorage.removeItem(BSGAuth.COGNITO_CREDENTIALS);
      localStorage.removeItem(BSGAuth.COGNITO_USER);
      localStorage.removeItem(BSGAuth.MYBSG_PERSON);
      resolve();
    });
  }

  static changePassword(params: {
    AccessToken: string;
    PreviousPassword: string;
    ProposedPassword: string;
  }) {
    return new Promise<any>((resolve, reject) => {
      //console.log("ChangePassword: " + JSON.stringify(params));
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      };
      fetch("/api/pub/cognito-changePassword", options)
        .then((response) => response.json())
        .then((json) => {
          if (json.status == "ok") {
            //console.log("json: " + JSON.stringify(json));
            resolve(json.user); // DCognitoUser
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static forgotPassword() {}

  static forgotPasswordSubmit() {}

  static verifyCurrentUserAttribute() {}

  static verifyCurrentUserAttributeSubmit() {}

  /**
   * my BSG operations
   *
   */
  // get user from local storage
  static bsgCurrentPerson() {
    return new Promise<any>((resolve, reject) => {
      let person = localStorage.getItem(BSGAuth.MYBSG_PERSON);
      if (person != null) {
        //console.log("credentials in localstorage: " + person);
        resolve(JSON.parse(person));
      } else {
        resolve(null);
        //throw new Error("current authenticated user is not found");
      }
      //else {
      //  return reject(new Error("current authenticated user is not found"));
      //}
    });
  }

  static bsgGetPerson(credentials: DCognitoCredentials, user: DCognitoUser) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/person", options)
        .then((response) => response.json())
        .then((json) => {
          if (json.status == "ok") {
            //console.log("json: " + JSON.stringify(json));
            localStorage.setItem(
              BSGAuth.MYBSG_PERSON,
              JSON.stringify(json.person)
            );
            resolve(json.person); // DCognitoUser
          } else {
            localStorage.removeItem(BSGAuth.MYBSG_PERSON);
            reject();
          }
        })
        .catch((err) => {
          localStorage.removeItem(BSGAuth.MYBSG_PERSON);
          reject(err);
        });
    });
  }
}
