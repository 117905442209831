import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Container, Fade, Grid } from "@mui/material";
import ThemeCard from "../components/card/theme-card";
import "../custom.d.ts";
import "./style.scss";
import Banner from "../components/banner/banner";
import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";
import Loading from "../components/loading/loading";

import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import ThreeDemo from "../components/three/three-demo";
import UMap from "./u-map";
//import { socket, SocketContext } from "../context/web-socket";

//export const socket = socketio.connect("ws://localhost:3001/");
//declare var socketio: any;

const Demo = (props: any) => {
  //const { children, window } = props;
  const { t, i18n } = useTranslation("content");

  const context = useContext(UserContext);
  //const socket = useContext(SocketContext);

  //const [socket, setSocket] = useState(null as unknown as io.Socket);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    /**
    BSGAPI.getWebContent("demo").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
      setLoading(false);
    });
    **/
    setLoading(false);

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Loading show={loading} />

      <div className="section-dark">
        <Container maxWidth="lg">
          <div>
            <h3 className="center warning-dark">
              Click on object to show info
            </h3>
            <UMap
              id="map-id-demo-123"
              src="https://my-bsg-asset.s3.amazonaws.com/map/map_sample1.png"
              width="100%"
              height="800px"
              refresh={1000}
              markerSize={10}
              step={40}
            />
          </div>
        </Container>
      </div>

      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h1?.title}</h1>
              </Grid>
              {webContent.h1?.children?.map((child) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <h2>{child.content}</h2>
                  </Grid>
                );
              })}
            </Grid>
          </Banner>
        </div>
      </Fade>

      <div className="center">
        <ContactForm />
      </div>
    </div>
  );
};

export default Demo;
