import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import Banner from "../components/banner/banner";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import { DOpportunity, DPerson, DUser, DWebContent } from "../model";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BSGAPI } from "../auth/bsg-api";
import OpportunityCard from "../components/card/opportunity-card";
import BSGModal from "../components/modal/bsg-modal";
import { ContextService } from "../context/context-service";
import HelpIcon from "@mui/icons-material/Help";

export interface Props {
  text: string;
  /**
     * 
    ok: boolean;
    id?: number;  // ? optional
    fn: (bob: string) => string;
    person: Person;
     */
}

const MyReferral = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useHistory();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [value, setValue] = React.useState("female");

  let [opportunities, setOpportunities] = useState([] as DOpportunity[]);

  let [opportunity, setOpportunity] = useState({} as DOpportunity);

  const [openNew, setOpenNew] = useState(false);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const addOpportunity = () => {
    //setOpportunities([...opportunities, { type: "test" }]);

    BSGAPI.updateOpportunity(
      context.user.cognitoCredentials,
      context.user.cognitoUser,
      //opportunities
      //[...opportunities, { type: "test" }]
      [...opportunities, { ...opportunity, date: new Date().toISOString() }]
    ).then((json) => {
      if (json.status == "ok") {
        setOpportunities(json.data as DOpportunity[]);
        setOpenNew(false);
      } else {
        // TODO: exception handling ..
      }
    });
  };

  const deleteOpportunity = (index: number) => {
    let newObjects = opportunities;
    newObjects.splice(index, 1);

    BSGAPI.updateOpportunity(
      context.user.cognitoCredentials,
      context.user.cognitoUser,
      //opportunities
      newObjects
    ).then((json) => {
      if (json.status == "ok") {
        setOpportunities(json.data as DOpportunity[]);
      } else {
        // TODO: exception handling ..
        //console.log("error deleting ...");
      }
    });
  };

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        BSGAPI.getOpportunity(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((json) => {
          if (json.status == "ok") {
            setOpportunities(json.data as DOpportunity[]);
          } else {
            // TODO: exception handling ..
          }
        });

        forceUpdate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/referral`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in");
      }
    });

    //loadWebContent();
    BSGAPI.getWebContent("referral").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid container item xs={12} justifyContent="center">
              You have{" "}
              <span className="warning-dark">{opportunities?.length}</span>{" "}
              active referrals.
            </Grid>

            {opportunities?.map((obj, index) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                >
                  <OpportunityCard
                    opportunity={obj}
                    onClickText="Delete"
                    onClick={() => {
                      deleteOpportunity(index);
                    }}
                  ></OpportunityCard>
                </Grid>
              );
            })}

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              justifyContent="center"
            >
              <OpportunityCard
                opportunity={null}
                onClickText="New"
                onClick={() => {
                  setOpenNew(true);
                }}
              ></OpportunityCard>

              <BSGModal
                title="Refer a friend"
                open={openNew}
                onClose={() => {
                  setOpenNew(false);
                }}
              >
                <Container maxWidth="md">
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    alignContent="center"
                  >
                    <Grid container item xs={12} justifyContent="center">
                      <h2 className="secondary-light">
                        What opportunity do you refer your friend for?
                      </h2>
                    </Grid>

                    <Grid container item xs={12}>
                      <RadioGroup
                        aria-label="gender"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={(e: any) => {
                          setOpportunity({
                            ...opportunity,
                            type: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="BSG Career Coaching Program"
                          control={<Radio color="secondary" size="medium" />}
                          label="BSG Career Coaching Program"
                          checked={
                            opportunity.type == "BSG Career Coaching Program"
                          }
                        />
                        <h5>
                          For each new qualified referral, you will be paid for
                          $50.{" "}
                        </h5>
                        <FormControlLabel
                          value="BSG Job Candidate"
                          disabled={true}
                          control={<Radio color="secondary" size="medium" />}
                          label="BSG Job Candidate - Coming Soon"
                          checked={opportunity.type == "BSG Job Candidate"}
                        />
                        <h5>
                          For each qualified referral, you will be paid for $500
                          ~ $1,000.{" "}
                        </h5>
                        <FormControlLabel
                          value="BSG Client"
                          disabled={true}
                          control={<Radio color="secondary" size="medium" />}
                          label="BSG Client - Coming Soon"
                          checked={opportunity.type == "BSG Client"}
                        />
                        <h5>
                          For each qualified referral, you will be paid for
                          $1,000 ~ $5,000.{" "}
                        </h5>
                      </RadioGroup>
                    </Grid>

                    <Grid container item xs={12} justifyContent="center">
                      <h2 className="secondary-light">
                        Your Friend's Contact Information
                      </h2>
                    </Grid>

                    <Grid container item xs={12} justifyContent="flex-start">
                      <TextField
                        required
                        variant="outlined"
                        color="secondary"
                        id="name"
                        label="Name"
                        value={opportunity?.person}
                        onChange={(e: any) => {
                          setOpportunity({
                            ...opportunity,
                            person: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-start">
                      <TextField
                        required
                        variant="outlined"
                        color="secondary"
                        id="linkedin"
                        label="LinkedIn"
                        value={opportunity?.linkedIn}
                        onChange={(e: any) => {
                          setOpportunity({
                            ...opportunity,
                            linkedIn: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-start">
                      <TextField
                        required
                        variant="outlined"
                        color="secondary"
                        id="mobile"
                        label="Mobile"
                        value={opportunity?.mobile}
                        onChange={(e: any) => {
                          setOpportunity({
                            ...opportunity,
                            mobile: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-start">
                      <TextField
                        required
                        variant="outlined"
                        color="secondary"
                        id="email"
                        label="E-mail"
                        value={opportunity?.email}
                        onChange={(e: any) => {
                          setOpportunity({
                            ...opportunity,
                            email: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-start">
                      <TextField
                        required
                        variant="outlined"
                        color="secondary"
                        id="wechat"
                        label="WeChat"
                        value={opportunity?.wechat}
                        onChange={(e: any) => {
                          setOpportunity({
                            ...opportunity,
                            wechat: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-start">
                      <TextField
                        required
                        variant="outlined"
                        color="secondary"
                        id="note"
                        label="Your brief message"
                        multiline
                        rows={2}
                        value={opportunity?.note}
                        onChange={(e) => {
                          setOpportunity({
                            ...opportunity,
                            note: e.target.value,
                          });
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid container item xs={12} justifyContent="center">
                      <h2 className="secondary-light">
                        Please read and follow our referral guidelines.
                      </h2>
                    </Grid>

                    <Grid container item xs={12} justifyContent="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            color="secondary"
                            size="medium"
                          />
                        }
                        label="I have reviewed and agreed on the following: "
                      />
                      <h5>
                        <li>
                          I know this person personally or professionally. If
                          you don't know this person, please don't refer.
                        </li>
                        <li>
                          I have explained to my friend about BSG and shared
                          information about BSG's program(s) indicated in this
                          referral.{" "}
                        </li>
                        <li>
                          I have permission to share my friend's contact
                          information with BSG in case BSG needs to reach out to
                          this person to initiate conversation about BSG's
                          program as indicated in this referral.
                        </li>
                      </h5>
                    </Grid>

                    <Grid container item xs={12} justifyContent="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          addOpportunity();
                        }}
                        disabled={false}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </BSGModal>
            </Grid>
          </Grid>
        </Container>
      </div>

      {webContent.faqs && (
        <div className="section-white">
          <Container maxWidth="md">
            <div>
              <h2>FAQs</h2>
            </div>
            {webContent.faqs?.map((question, index) => (
              <Accordion key={`faq-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h2 className="secondary-light">{question.question}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h3>${question.answer}</h3>`,
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </div>
      )}
    </div>
  );
};

export default MyReferral;
