import React, { useEffect, useRef, useState } from "react";
import useDynamicRef from "../hooks/dynamic-ref";
import UObjectInfo from "./object-info";
import { UObject } from "./u-object";
import { DMap, DObject } from "./ubq";
import { io } from "socket.io-client";

import "./style.scss";
import { TrackChangesTwoTone } from "@mui/icons-material";

const UMap = ({ id, src, width, height, refresh, markerSize, step }: DMap) => {
  const [getRef, setRef] = useDynamicRef();

  const divRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const timeStep: number = refresh ? refresh : 10000;

  const objectSize: number = markerSize ? markerSize : 10;

  const walkStep: number = step ? step : 10;

  let [timer, setTimer] = useState(null as any);

  const [objects, setObjects] = useState([] as DObject[]);

  /**/
  let tracker = useRef({
    objects: [] as UObject[],
    ctx: null as any,
    //socket: null as unknown as io.Socket,
    socket: null as unknown as any,
  
  });
  /**/

  //let [ctx, setCtx] = useState(null as any);

  // render Map
  const init = () => {
    let canvas = canvasRef.current;

    if (canvas) {
      let ctx = canvas?.getContext("2d");

      if (ctx) {
        tracker.current.ctx = ctx;
        /**        tracker.current.objects = UObject.randomInit(
          tracker.current.ctx,
          divRef.current?.offsetWidth as number,
          divRef.current?.offsetHeight as number
        );

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        **/

        //setObjects(UObject.randomInit(ctx as any));
      }
    }
  };

  useEffect(() => {
    init();
    return () => {
      //clean up
      tracker.current?.objects.forEach((object) => {
        //objects.forEach((object) => {
        (object as UObject).release();
      });
    };
  }, []);

  const timing = () => {
    // do nothing ...
    let myTimer = setTimeout(() => {
      timing();
    }, timeStep);

    setTimer(myTimer);
  };

  useEffect(() => {
    timing();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    console.log("called ....");
    if (!tracker.current.socket) {
      //if (!socket) {
      console.log("[u-map] Connecting .... web socket ...");
      //tracker.current.socket = io.connect("/");
      tracker.current.socket = io();
    
    } else {
      // subscribe to socket events
      tracker.current.socket.on("connect", () => {
        console.log("[u-map] Connected ...." + tracker.current.socket.id);
        tracker.current.socket.emit("c-map-init", {
          id: id,
          width: tracker.current.ctx.canvas.width,
          height: tracker.current.ctx.canvas.height,
        });
      });
      tracker.current.socket.on("connect_error", (err: any) => {
        console.log("[u-map] Connect Error ...." + err);
      });
      tracker.current.socket.on("disconnect", () => {
        //console.log("Disconnected ...." + tracker.current.socket.id);
      });

      tracker.current.socket.on("error", (err: any) => {
        console.log("[u-map] Websocket Error ...." + err);
      });

      tracker.current.socket.on("s-map-welcome", (msg: any) => {
        //console.log("Received welcome from server ..." + msg);
      });

      // server sent initiation
      tracker.current.socket.on("s-send-iots", (objects: any) => {
        //console.log("Map welcome  ...." + msg);
        tracker.current.objects = objects.map((obj: DObject) => {
          return new UObject(obj as DObject, tracker.current.ctx);
        });

        tracker.current.ctx.clearRect(
          0,
          0,
          tracker.current.ctx.canvas.width,
          tracker.current.ctx.canvas.height
        );
      });

      // receive an object update from server
      tracker.current.socket.on("s-map-object", (object: any) => {
        //console.log("Map welcome  ...." + msg);

        tracker.current.objects = tracker.current.objects.filter(function (
          obj
        ) {
          return obj.id !== object.id;
        });
        tracker.current.objects.push(new UObject(object, tracker.current.ctx));
        console.log("got existing object: " + JSON.stringify(object));
        //obj.go(object.x, object.y);
        //tracker.current.objects.splice(index, 1);
        //tracker.current.objects.push(
        //  new UObject(object, tracker.current.ctx)
        //);
        //}
        /** 
        tracker.current.ctx.clearRect(
          0,
          0,
          tracker.current.ctx.canvas.width,
          tracker.current.ctx.canvas.height
        );*/
      });
    }

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      if (tracker.current.socket && tracker.current.socket.connected) {
        tracker.current.socket.disconnect();
      }
    };
  }, [tracker.current.socket]);

  return (
    <>
      <div
        ref={divRef}
        style={{
          border: "0px solid green",
          backgroundImage: `url(${src})`,
          width: width,
          height: height,
          padding: 0,
          margin: 0,
        }}
      >
        <canvas
          ref={canvasRef}
          width={divRef.current?.offsetWidth}
          height={divRef.current?.offsetHeight}
        />
      </div>
      <div>
        <h2>Object Size: {tracker.current.objects.length}</h2>
        {tracker.current.objects.map((object) => {
          return (
            <div
              key={`div-${object.id}`}
              ref={setRef(object.id) as React.RefObject<HTMLDivElement>}
              className="object-info"
              style={{
                position: "absolute",
                top: divRef.current!.offsetTop + object.y - objectSize, //UObject.RADIUS,
                left: divRef.current!.offsetLeft + object.x - objectSize, //UObject.RADIUS,
              }}
            >
              <UObjectInfo object={{ ...object, size: objectSize }} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default UMap;
