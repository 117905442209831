import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Container, Fade, Grid } from "@mui/material";
import ThemeCard from "../components/card/theme-card";
import "../custom.d.ts";
import "./style.scss";
import Banner from "../components/banner/banner";
import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";
import YouTubeCard from "../components/card/youtube-card";
import BSGModal from "../components/modal/bsg-modal";
import IconCard from "../components/card/icon-card";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import EmojiSymbolsOutlinedIcon from "@mui/icons-material/EmojiSymbolsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

import TouchAppIcon from "@mui/icons-material/TouchApp";
import BusinessIcon from "@mui/icons-material/Business";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PollIcon from "@mui/icons-material/Poll";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EvStationIcon from "@mui/icons-material/EvStation";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import MoreIcon from "@mui/icons-material/More";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ApiIcon from "@mui/icons-material/Api";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import SpeedIcon from "@mui/icons-material/Speed";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import { useHistory } from "react-router-dom";
import Loading from "../components/loading/loading";

const Product = (props: any) => {
  //const { children, window } = props;
  const { t, i18n } = useTranslation("content");

  const context = useContext(UserContext);

  const history = useHistory();
  const [webContent, setWebContent] = useState({} as DWebContent);
  const [loading, setLoading] = useState(true);

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const [openRetail, setOpenRetail] = React.useState(false);
  const [openLogistics, setOpenLogistics] = React.useState(false);
  const [openHealthcare, setOpenHealthcare] = React.useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    BSGAPI.getWebContent("product").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
      setLoading(false);
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Loading show={loading} />
      {webContent.theme && (
        <ThemeCard
          backgroundImg={webContent.theme.image}
          opacity={0.9}
          title={webContent.theme.title as string}
          description={webContent.theme.description as string}
          minHeight={webContent.theme.height}
          caption={webContent.theme.caption}
          route={webContent.theme.route}
          routeText={webContent.theme.routeText}
        >
          <Container maxWidth="md">
            <div className="section">
              <h1 className="center secondary-light">
                {webContent.theme.h1?.title}
              </h1>
              {webContent.theme.h1?.children?.map((child) => {
                return (
                  <h2 className="center secondary-light">{child.content}</h2>
                );
              })}
            </div>
          </Container>

          <Container maxWidth="lg">
            <Grid
              container
              spacing={6}
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              alignContent="center"
            >
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                justifyContent="center"
              >
                <IconCard
                  icon={<MoreIcon color="secondary" style={{ fontSize: 64 }} />}
                  title="Product 1"
                  description="We help business solve problems"
                  onClick={() => {
                    setOpenRetail(true);
                  }}
                  onClickText="Read more ..."
                />

                <BSGModal
                  title="Retail - Problem Solving"
                  open={openRetail}
                  onClose={() => {
                    setOpenRetail(false);
                  }}
                >
                  <Container maxWidth="lg">
                    <Grid
                      container
                      spacing={10}
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <Grid container item xs={12} justifyContent="center">
                        <h2 className="center secondary-light">
                          Our experience and expertise is focused on the
                          following specific areas.
                        </h2>
                        <h3 className="center">
                          <a
                            href="#"
                            onClick={() => {
                              history.push("/contact-us");
                            }}
                          >
                            Contact us today
                          </a>{" "}
                          to explore how we may help accelerate strategy
                          adoption.
                        </h3>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <CloudDoneIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Cloud Migration"
                          description="Cloud vendor assessment, solution architecture and cloud engineering"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BubbleChartOutlinedIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Application Dev"
                          description="New application development, or legacy application modernization"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <CompareArrowsIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Data Engineering"
                          description="From data platform to ETL processing"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BusinessIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Small Business Package"
                          description="We help small business to  more technology with less"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BubbleChartOutlinedIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Vendor Assessment"
                          description="Assess vendor product(s) and make production and solution recommendation"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BubbleChartOutlinedIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Architecture Assessment"
                          description="Analyze and document current state and propose & recommend future state"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BubbleChartOutlinedIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Retail"
                          description="We help you to define the problem (Analysis), lay our a recommendation and plan (Product & Planning), then get it done (Engineering & Implementation)"
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </BSGModal>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                justifyContent="center"
              >
                <IconCard
                  icon={
                    <AdminPanelSettingsIcon
                      color="secondary"
                      style={{ fontSize: 64 }}
                    />
                  }
                  title="Product 2"
                  description="We build innovative products & solutions"
                  onClick={() => {
                    setOpenLogistics(true);
                  }}
                  onClickText="Read more ..."
                />

                <BSGModal
                  title="Logistics - Together, let's build great things"
                  open={openLogistics}
                  onClose={() => {
                    setOpenLogistics(false);
                  }}
                >
                  <Container maxWidth="lg">
                    <Grid
                      container
                      spacing={10}
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <Grid container item xs={12} justifyContent="center">
                        <h2 className="center secondary-light">
                          Our experience and expertise is focused on the
                          following specific areas.
                        </h2>
                        <h3 className="center">
                          <a
                            href="#"
                            onClick={() => {
                              history.push("/contact-us");
                            }}
                          >
                            Contact us today
                          </a>{" "}
                          to explore how we may help you accelerate project
                          development.
                        </h3>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <ChangeCircleIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Data Pipeline"
                          description="Develop pipeline to automate data migration, data ingestion, ETL, monitoring and reporting"
                        />
                      </Grid>

                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <TouchAppIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Front-end Development"
                          description="Develop modern web application using popular frameworks including Angular and React"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <ApiIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="API & Microservices Development"
                          description="Develop modern container and serverless backend using RESTFul and GraphQL APIs"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <SettingsEthernetIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="DevOps CICD Pipeline"
                          description="Develop Infrastructure-as-Code and CICD automation"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <SpeedIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Testing"
                          description="QA testing automation"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <CloudDoneIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Cloud CRM Platform Development"
                          description="Develop application on Cloud Platform for Salesforce and Microsoft Dynamnics"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BuildCircleIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Custom Dev & Integration"
                          description="We partner with hundreds of technology providers and build solution to provide seamless integration and user experience. Please ask. "
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </BSGModal>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                justifyContent="center"
              >
                <IconCard
                  icon={
                    <NextWeekIcon color="secondary" style={{ fontSize: 64 }} />
                  }
                  title="Product 3"
                  description="We train professionals with real project experience"
                  onClick={() => {
                    setOpenHealthcare(true);
                  }}
                  onClickText="Read more ..."
                />

                <BSGModal
                  title="Healthcare - Our experience to support your success"
                  open={openHealthcare}
                  onClose={() => {
                    setOpenHealthcare(false);
                  }}
                >
                  <Container maxWidth="lg">
                    <Grid
                      container
                      spacing={10}
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <Grid container item xs={12} justifyContent="center">
                        <h3 className="center">
                          <a
                            href="#"
                            onClick={() => {
                              history.push("/contact-us");
                            }}
                          >
                            Contact us today
                          </a>{" "}
                          to explore how we may help you accelerate career
                          development.
                        </h3>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BubbleChartOutlinedIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Career Coaching/Healthcare"
                          description="Soft skill, leadership and communication that you need for professional career development"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        justifyContent="center"
                      >
                        <IconCard
                          icon={
                            <BubbleChartOutlinedIcon
                              color="secondary"
                              style={{ fontSize: 48 }}
                            />
                          }
                          title="Technical Healthcare "
                          description="Technical training for IT/Data Consultant"
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </BSGModal>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="md">
            <div className="section">
              <h2 className="center secondary-light">
                {webContent.theme.h2?.title}
              </h2>
              {webContent.theme.h2?.children?.map((child) => {
                return (
                  <h3 className="center secondary-light">{child.content}</h3>
                );
              })}
            </div>
          </Container>
        </ThemeCard>
      )}

      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h1?.title}</h1>
              </Grid>
              {webContent.h1?.children?.map((child) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <h2>{child.content}</h2>
                  </Grid>
                );
              })}
            </Grid>
          </Banner>
        </div>
      </Fade>

      <div className="center">
        <ContactForm />
      </div>
    </div>
  );
};

export default Product;
