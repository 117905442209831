import "./App.css";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Nav from "./components/nav/nav";
import Test from "./pages/test/Test";
import { UserContextProvider } from "./context/user-context";
import { ContextService } from "./context/context-service";
import Footer from "./components/footer/footer";

import { theme } from "./theme/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Services } from "./pages/services";
import Test2 from "./pages/test2/Test2";
import Post1 from "./posts/Post1";
import Post2 from "./posts/Post2";
import Test3 from "./pages/test3/Test3";
import CareerCoaching from "./pages/career-coaching";
import ComingSoon from "./pages/coming-soon";
import ILab from "./pages/ilab";
import CookieConsent from "./components/consent/cookie-consent";
import Jobs from "./pages/jobs";
import React, { useEffect, useState } from "react";
import SignIn from "./my/sign-in";
import Training from "./pages/training";
import SignOut from "./my/sign-out";
import Contact from "./pages/contact";
import CaseStudy from "./pages/use-study";
import Events from "./pages/events";

import awsconfig from "./api/aws-amplify";
import Amplify from "aws-amplify";
import SignUp from "./my/sign-up";
import ChangePassword from "./my/change-password";
import MyHome from "./my/my-home";
import ForgotPassword from "./my/forgot-password";
import MyPayment from "./my/my-payment";
import MyDocument from "./my/my-document";
import MyCandidates from "./my/my-candidates";
import Candidate from "./my/my-candidate";
import MyProfile from "./my/my-profile";
import { DBusiness, DUser } from "./model";
import MySchedule from "./my/my-schedule";
import MyReferral from "./my/my-referral";
import About from "./pages/about";
import Solution from "./pages/solution";
import Home from "./pages/home";
import Partner from "./pages/partner";
import Investor from "./pages/investor";
import HowWork from "./pages/how-it-works";
import Product from "./pages/product";
import Demo from "./ubq/demo";
import UAdmin from "./ubq/u-admin";

Amplify.configure(awsconfig);

function App() {
  //const context = useContext(userContext);

  let [context, setContext] = useState(new ContextService());

  let [contextUpdate, setContextUpdate] = useState(false);

  const forceUpdate = () => {
    setContextUpdate(!contextUpdate);
  };

  useEffect(() => {
    //document.title = "BSG:Welcome";
    context.init().then(() => {
      //context.setUser(data.user as DUser);
      //context.setBusiness(data.business as DBusiness);
      //console.log("Init: " + JSON.stringify(context.user));
      //forceUpdate();
    });
  }, []);

  return (
    <UserContextProvider value={{ context, contextUpdate, forceUpdate }}>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <Nav></Nav>
            <div className="App-main">
              <Route path="/" exact component={Home} />
              <Route path="/home" exact component={Home} />
              <Route path="/about" exact component={About} />
              <Route path="/products" exact component={Product} />
              <Route path="/solutions" exact component={Solution} />
              <Route path="/demo" exact component={Demo} />
              <Route path="/admin" exact component={UAdmin} />
              <Route path="/how-it-works" exact component={HowWork} />
              <Route path="/partners" exact component={Partner} />
              <Route path="/investor-relationship" exact component={Investor} />
              <Route path="/jobs" exact component={Jobs} />
              <Route path="/jobs/:id" exact component={Jobs} />
              <Route path="/jobs/:id/:action" exact component={Jobs} />
              <Route path="/training" exact component={Training} />
              <Route path="/ilab" exact component={ILab} />
              <Route path="/case-study" exact component={CaseStudy} />
              <Route path="/case-study/:category" exact component={CaseStudy} />
              <Route path="/contact-us" exact component={Contact} />
              <Route path="/career-coaching" exact component={CareerCoaching} />
              <Route path="/profile" exact component={MyProfile} />
              <Route path="/pay" exact component={MyPayment} />
              <Route path="/referral" exact component={MyReferral} />
              <Route path="/candidates" exact component={MyCandidates} />
              <Route path="/candidate/:id" exact component={Candidate} />
              <Route path="/documents" exact component={MyDocument} />
              <Route path="/schedule" exact component={MySchedule} />
              <Route path="/sign-in" exact component={SignIn} />
              <Route path="/sign-up" exact component={SignUp} />
              <Route path="/sign-up/:step" exact component={SignUp} />
              <Route path="/sign-out" exact component={SignOut} />
              <Route path="/change-password" exact component={ChangePassword} />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route
                path="/forgot-password/:step"
                exact
                component={ForgotPassword}
              />
              <Route path="/events" exact component={Events} />
              <Route path="/events/:id" exact component={Events} />
              <Route path="/events/:id/:action" exact component={Events} />
              <Route path="/test" exact component={Test} />
              <Route path="/test2" exact component={Test2} />
              <Route path="/test3" exact component={Test3} />
              <Route path="/services" exact component={Services} />
              <Route path="/post1" exact component={Post1} />
              <Route path="/post2" exact component={Post2} />
              <Route path="/coming-soon" exact component={ComingSoon} />
            </div>
            <Footer></Footer>
            <CookieConsent></CookieConsent>
          </div>
        </Router>
      </ThemeProvider>
    </UserContextProvider>
  );
}

export default App;
