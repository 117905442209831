import {
  CircularProgress,
  Container,
  Fab,
  Fade,
  Grid,
  Paper,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import { UserContext } from "../../context/user-context";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "./style.scss";
import { Link, useHistory } from "react-router-dom";
import QRCode from "../qrcode";
import { DContent } from "../../model";
import { UserContext } from "../../context/user-context";

export default function Loading(props: any) {
  return props.show ? (
    <CircularProgress color="secondary" sx={{ fontSize: 280 }} />
  ) : (
    <></>
  );
}
