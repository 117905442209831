import React from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { DDay } from "../../model";
import { BSGCalendar } from "./bsg-calendar";

const CalendarHour = (props: any) => {
  const history = useHistory();
  const routeTo = (route: string) => {
    history.push(route);
  };

  const hour = props.hour as number;

  const today = new Date();

  return (
    <div className="day-card">
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        <li key={`hour-${hour}`}>
          <ul>
            {[0, 1, 2, 3].map((item) => (
              <ListItem key={`item-${hour}-${item}`}>
                <ListItemText primary={`${hour}: ${item * 15}`} />
              </ListItem>
            ))}
          </ul>
        </li>
      </List>
    </div>
  );
};

export default CalendarHour;
