import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Container, Grid } from "@mui/material";
import CalendarDay from "./calendar-day";
import { DCalendarItem, DDay } from "../../model";
import { BSGCalendar } from "./bsg-calendar";

const CalendarWeek = (props: any) => {
  const history = useHistory();
  const routeTo = (route: string) => {
    history.push(route);
  };

  const startDate = props.startDate as Date;

  const today = new Date();

  const [days, setDays] = useState([] as DDay[]);

  const loadDays = () => {
    let myDays = [] as DDay[];
    for (let i = 0; i < 7; i++) {
      myDays.push({
        date: BSGCalendar.current().getDate(startDate, i),
        event: "random",
      } as DDay);
    }
    setDays(myDays);
  };

  useEffect(() => {
    loadDays();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        p: 0,
        m: 0,
        bgcolor: "background.paper",
        width: "100%",
        border: "0px solid silver",
      }}
    >
      {days ? days.map((day) => <CalendarDay day={day} />) : ""}
    </Box>
  );
};

export default CalendarWeek;
