import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Container, Fade, Grid } from "@mui/material";
import ThemeCard from "../components/card/theme-card";
import "../custom.d.ts";
import "./style.scss";
import Banner from "../components/banner/banner";
import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";
import Loading from "../components/loading/loading";

const Partner = (props: any) => {
  //const { children, window } = props;
  const { t, i18n } = useTranslation("content");

  const context = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    BSGAPI.getWebContent("partner").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;

      setLoading(false);
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Loading show={loading} />
      {webContent.theme && (
        <ThemeCard
          backgroundImg={webContent.theme.image}
          opacity={0.9}
          title={webContent.theme.title as string}
          description={webContent.theme.description as string}
          minHeight={webContent.theme.height}
          caption={webContent.theme.caption}
          route={webContent.theme.route}
          routeText={webContent.theme.routeText}
        >
          <Container maxWidth="md">
            <h1 className="center secondary-light">
              {webContent.theme.h1?.title}
            </h1>
            {webContent.theme.h1?.children?.map((child) => {
              return (
                <h2 className="center secondary-light">{child.content}</h2>
              );
            })}
            <h2 className="center secondary-light">
              {webContent.theme.h2?.title}
            </h2>
            {webContent.theme.h2?.children?.map((child) => {
              return (
                <h3 className="center secondary-light">{child.content}</h3>
              );
            })}
          </Container>
        </ThemeCard>
      )}

      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h1?.title}</h1>
              </Grid>
              {webContent.h1?.children?.map((child) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <h2>{child.content}</h2>
                  </Grid>
                );
              })}
            </Grid>
          </Banner>
        </div>
      </Fade>

      <div className="center">
        <ContactForm />
      </div>
    </div>
  );
};

export default Partner;
