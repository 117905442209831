import {
  Box,
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Slide,
  TextField,
  Tooltip,
  useScrollTrigger,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "./style.scss";
import { Link } from "react-router-dom";
import { DInquiry, DNameValue } from "../../model";
import { API } from "aws-amplify";
import { useState } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export interface ISelectList {
  label?: string;
  tip?: string;
  editMode?: boolean;
  name?: string;
  value?: string;
  list?: string[];
  onChange?: any;
}

const BSelectList = ({
  label,
  tip,
  editMode,
  name,
  value,
  list,
  onChange,
}: ISelectList) => {
  const [myEditMode, setMyEditMode] = useState(editMode);

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <h3>
          {label}
          {tip && (
            <Tooltip title={tip} arrow>
              <HelpOutlineIcon style={{ fontSize: 20, padding: 2 }} />
            </Tooltip>
          )}
        </h3>
      </Box>

      {myEditMode ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Box sx={{ flexGrow: 1, justifyContent: "flex-stretch" }}>
            <Select
              labelId="item-label"
              id="item-select"
              value={value}
              label={label}
              onChange={onChange}
            >
              {list?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Box>
          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                setMyEditMode(false);
              }}
            >
              <CheckCircleIcon />
            </IconButton>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Box
            flexGrow={1}
            sx={{ border: "1px solid silver", p: 1, borderRadius: "15px" }}
          >
            <h3 className="left warning-dark">{value ? value : "(not set)"}</h3>
          </Box>
          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                setMyEditMode(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </div>
      )}
    </div>
  );
};

export default BSelectList;
