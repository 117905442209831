import { ContextService } from "../context/context-service";
import {
  DCognitoCredentials,
  DCognitoUser,
  DNameValue,
  DOpportunity,
} from "../model";

export class BSGAPI {
  static WEB_CONTENT_ROOT =
    "https://my-bsg-json.s3.amazonaws.com/content/ubq-web";

  static RegExp_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static RegExp_PHONE =
    /(^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$){1}/;

  static RegExp_PHONE_ALLOWED_INPUT = /[\d-. \(\)]/;
  //static RegExp_PASSWORD = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/; // require at least 1 number and 1 special characters,
  static RegExp_PASSWORD = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  static RegExp_RECOVERY_CODE = /^[a-zA-Z0-9!@#$%^&*]{4,10}$/;

  constructor() {}

  static config() {
    //console.log("This is config of BSG API class");
  }

  static getOpportunity(credentials: DCognitoCredentials, user: DCognitoUser) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/opportunity", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getOpportunitySummary(
    credentials: DCognitoCredentials,
    user: DCognitoUser
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/opportunity-summary", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static updateOpportunity(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    objects: DOpportunity[]
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          data: objects,
        }),
      };
      fetch("/api/my/opportunity-update", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getPayments(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    person: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: {
            person: person,
          },
        }),
      };
      fetch("/api/my/payments", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getPaymentSummary(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    person: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: {
            person: person,
          },
        }),
      };
      fetch("/api/my/payment-summary", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getUserAttributes(
    credentials: DCognitoCredentials,
    user: DCognitoUser
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/user-attribute", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static updateUserAttributes(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    attributes: DNameValue[]
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, data: attributes }),
      };
      fetch("/api/my/user-attribute-update", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getBusiness() {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Cache-Control": "No-Cache",
          //"Cache-Control": `${ContextService.CACHE_MAX_AGE}`,
          Authorization: "", // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate      },
        },
        queryStringParameters: {
          //person: context.user.person?.id,
        },
      };
      fetch(`${this.WEB_CONTENT_ROOT}/business.json`, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static getWebContent(contentKey: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          //"Cache-Control": "No-Cache",
          "Cache-Control": `${ContextService.CACHE_MAX_AGE}`,
          Authorization: "", // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate      },
        },
        queryStringParameters: {
          //person: context.user.person?.id,
        },
      };
      fetch(`${this.WEB_CONTENT_ROOT}/${contentKey}.json`, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // format a database string format
  static formatDateString(dateStr: string | undefined) {
    if (dateStr == null || dateStr == undefined) dateStr = "(n/a)";
    let splits = dateStr.split("T");
    if (splits.length > 0) return splits[0];
    else return "(n/a)"; // not standard string, just pass an empty string.
  }

  // a generic data format validation method
  static validate(
    regEx: RegExp,
    name: string,
    value: string | undefined | null,
    help: string | undefined | null
  ) {
    if (value == null || value == undefined)
      return { isValid: true, err: `${name} cannot be empty` };
    let isValid = regEx.test(value.toLowerCase());
    let err = isValid ? "" : help ? help : `please provide a valid ${name}`;
    return { isValid: isValid, err: err };
  }

  // mask/transform a string to a masked value
  // mask: +1 DDD DDD DDDD
  // string： 828888888
  //static RegExp_PHONE_ALLOWED_INPUT = /^\+?[0-9\+ .-]/;
  static mask(mask: string, value: string): { value: string; cursor: number } {
    //let digits = value.substr(2).replace(/\D/g, ""); // start after +1
    let digits = value.replace(/\D/g, ""); // start after +1
    let masked = "";
    let index = 0;
    let cursor = 0;
    for (let i = 0; i < mask.length; i++) {
      if (mask.charAt(i) === "D") {
        if (index < digits.length) {
          masked = masked.concat(digits.charAt(index));
          index++;
          cursor = i; // track where the cursor is.
        } else {
          masked = masked.concat(mask.charAt(i));
        }
      } else {
        masked = masked.concat(mask.charAt(i));
      }
    }
    return { value: masked, cursor: cursor };
  }
}
